// 
// _layouts.scss
// 

body[data-layout-size="boxed"] {
    background-color: var(--#{$prefix}boxed-body-bg);
    #layout-wrapper {
      // background: linear-gradient(263.49deg, #2F0032 8.91%, #00272A 99.26%);
      background-color: var(--#{$prefix}body-bg);
      max-width: $boxed-layout-width;
      margin: 0 auto;
      box-shadow: $box-shadow;
    }

  #page-topbar {
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    margin: 0 auto;
  }

  .footer {
    margin: 0 auto;
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
}

&.vertical-collpsed {
    .footer {
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
    }

  }

  &[data-sidebar-size="small"] {
    #page-topbar {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-width-sm});
      // left: $sidebar-width-sm;
      @media (max-width: 991.98px) {
        left: 0;
      }
    }
  }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &.vertical-collpsed {
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
    }
}


// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"] {
  #page-topbar,
  #layout-wrapper,
  .footer {
    max-width: 100%;
  }
  .container-fluid,
  .navbar-header {
    max-width: $boxed-layout-width;
  }

  &.vertical-collpsed {
    .main-content{
      min-height: auto;
    }
  }
}

// Scrollable layout

body[data-layout-scrollable="true"] {
  @media (min-width: 992px) {
    #page-topbar,
    .vertical-menu,
    .navbar-brand-box {
      position: absolute;
    }
  }

  &[data-layout="horizontal"] {
    @media (min-width: 992px) {
      #page-topbar,
      .topnav {
        position: absolute;
      }
      .navbar-brand-box {
        position: relative;
      }
    }
  }
}
